const sColors = [
    {
        sentiment: 'Positivo',
        color: '#47A025'
    },
    {
        sentiment: 'Neutral',
        color: '#61E8E1'
    },
    {
        sentiment: 'Negativo',
        color: '#F25757'
    }
];

export { sColors }